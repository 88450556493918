<script>
import { mapState } from 'vuex'

import { CountryEnum, ModeEnum } from '../store/modules/settings/types'
import SingleSelectedItemList from './diagnosis-lists/automation-mode/SingleSelectedItemList.vue'

export default {
  components: {
    'single-selected-item-list': SingleSelectedItemList,
  },
  props: {
    appSettings: { type: Object, required: true },
    rumId: { type: Number, required: true },
    rumPredictedLabels: { type: Array, required: true },
    diagnosisGroup: { type: Object, default: null },
    healthData: { type: Object, required: true },
    selection: { type: Object, default: null },
    isLoading: { type: Boolean, default: false },
    displayedRumIdx: { type: Number, required: true },
  },
  emits: ['make-selection'],
  data() {
    return {
      mode: ModeEnum.AUTOMATION,
      CountryEnum,
    }
  },
  computed: {
    ...mapState({
      pmsiCountry: (state) => state.settings.pmsi.country,
    }),
  },
}
</script>

<template>
  <div class="card-container">
    <single-selected-item-list
      :app-settings="appSettings"
      :rum-id="rumId"
      :diagnosis-group="diagnosisGroup"
      :selection="selection"
      :is-loading="isLoading"
      :health-data="healthData"
      :displayed-rum-idx="displayedRumIdx"
      :rum-predicted-labels="rumPredictedLabels"
      class="principal-diagnosis-group mb-3"
      type="DP"
      @make-selection="(selection) => $emit('make-selection', selection)"
    />
    <single-selected-item-list
      v-if="pmsiCountry === CountryEnum.CH || diagnosisGroup.currentDR || (diagnosisGroup.currentDP && diagnosisGroup.currentDP.reference[0] === 'Z')"
      :app-settings="appSettings"
      :rum-id="rumId"
      :diagnosis-group="diagnosisGroup"
      :selection="selection"
      :is-loading="isLoading"
      :health-data="healthData"
      :displayed-rum-idx="displayedRumIdx"
      :rum-predicted-labels="rumPredictedLabels"
      class="related-diagnosis-group mb-3"
      type="DR"
      @make-selection="(selection) => $emit('make-selection', selection)"
    />
  </div>
</template>
