
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import _ from 'lodash'
import { mapState } from 'vuex'

import useI18n, { Gender } from '@/i18n'
import { CriteriaList } from '@/store/modules/filters/types.js'
import { RootState } from '@/store/types.js'

import { documentCategories, textualHealthDataCategories } from '../stay-displayer/health-data/documentLabels.js'
import filtersData from './FiltersData'
import MultipleValueFilter from './MultipleValueFilter.vue'

const t = useI18n().global.t

const makeRangeLabel = (lowValue: string, highValue: string, suffix = '', gender: Gender = Gender.Masculine) => {
  if(highValue[0] === '>' &&  lowValue[0] === '<') {
    return t('range.allValues')
  }

  if ((lowValue === '0' && highValue[0] === '>') || !highValue || highValue[0] === '>') {
    return t(`range.greaterOrEqual.${gender}`, { value: lowValue, suffix })
  }

  if(lowValue === highValue) {
    return t(`range.equal.${gender}`, { value: lowValue, suffix })
  }

  if (!lowValue || lowValue === '0' || lowValue[0] === '<') {
    return t(`range.lessOrEqual.${gender}`, { value: highValue, suffix })
  }

  return t('range.rangeBetween', { lowValue, highValue, suffix })
}

export default {
  components: {
    'sancare-octicon': SancareOcticon,
    'multiple-value-filter': MultipleValueFilter,
  },
  props: {
    canRemoveCriteria: { type: Boolean, default: true },
    criteriaGroup: { type: Object, required: true },
    internalOperator: { type: String, required: true },
    displayGroupedCriteria: { type: Boolean, default: false }, // group criteria with same type
    isGlobal: { type: Boolean, required: false, default: false }, // is global criteria
    maxCriteria: { type: Number, default: 0 },
    mode: { type: String, required: true },
  },
  emits: ['remove-criteria'],
  data() {
    return {
      criteriaData: filtersData,
      textualReportCategories: { ...documentCategories, ...textualHealthDataCategories },
    }
  },
  computed: {
    criteriaList(): CriteriaList {
      const criteriaList: CriteriaList = {}
      _.forEach(this.criteriaGroup.criteriaList, (currentField) => {
        const criteriaType = currentField.type
        const currentValue = currentField.value

        if (currentValue) {
          const label = this.getCriteriaLabel(criteriaType)
          if (criteriaList[criteriaType]) {
            criteriaList[criteriaType].currentValues.push({
              id: currentField.id,
              rawContent: currentValue,
              content: this.renderCriteria(criteriaType, currentValue),
              type:criteriaType,
              modifiers: this.getModifiers(currentValue)
            })
          } else {
            criteriaList[criteriaType] = {
              label,
              currentValues: [{
                id: currentField.id,
                rawContent: currentValue,
                content: this.renderCriteria(criteriaType, currentValue),
                type:criteriaType,
                modifiers: this.getModifiers(currentValue)
              }]
            }
          }
        }
      })

      return criteriaList
    },
    ...mapState({
      healthConstantsList: (state: RootState) =>  state.medicalUnitFilter.remoteLists['constants'],
      pmsiCountry: (state: RootState) => state.settings.pmsi.country,
    }),
  },
  methods: {
    getCriteriaLabel(rawCriteriaType) {
      const splitCriteriaType = rawCriteriaType.split('__')
      const criteriaType = splitCriteriaType[0]
      if(splitCriteriaType.length > 1){
        return `${t(`filter.labels.${criteriaType}`)} (${this.textualReportCategories[splitCriteriaType[1]].toLowerCase()})`
      }
      return t(`filter.labels.${criteriaType}`)
    },
    getConstantTitle(constantId) {
      const constant = this.healthConstantsList.find((c) => c.id === Number(constantId))

      return constant ? constant.description : ''
    },
    renderCriteria(rawType, value) {
      if (!value) {
        return ''
      }
      const type = rawType.indexOf('__') === -1 ? rawType : rawType.split('__')[0]

      switch (type) {
        case 'rumCount':
          value = value.split('_')
          return makeRangeLabel(value[0], value[1])

        case 'presentContent':
        case 'absentContent':
        // If there is a \x1F separator between the modifiers and the text, we show what's after the separator
        // If there is no \x1F, then the substring will start from 0, effectively doing nothing.
          return value.substring(value.indexOf('\x1F') + 1)

        case 'stayDuration':
          value = value.split('_')
          // We replace 0 by null, to avoid having a label like "0j to 2j" but rather "<2j"
          return makeRangeLabel(value[0], value[1], 'j', Gender.Feminine)

        case 'patientAge':
          value = value.split('_')
          return makeRangeLabel(value[0], value[1], ' ans')

        case 'startingDmsGap':
        case 'currentDmsGap':
          value = value.split('_')
          return makeRangeLabel(value[0], value[1], 'j')

        case 'healthConstant':
          value = value.split('\x1F')
          return `${this.getConstantTitle(value[0])} ${makeRangeLabel(value[1], value[2])}`

        case 'biologyResult':
          value = value.split('\x1F')
          if (value.length === 3) {
            return `${value[0]} ${makeRangeLabel(value[1], value[2])}`
          }

          return `${value[0]} à ${value[1]}`

        case 'compareDiagnosis':
        case 'gender':
        case 'hasSession':
        case 'releaseMode':
        case 'admissionMode':
        case 'origin':
        case 'destination':
          if (this.criteriaData[rawType].optionsLocalised) {
            return this.criteriaData[rawType].options.find((opt) => opt.country === this.pmsiCountry && opt.value === value).label
          }
          return this.criteriaData[rawType]['labels'][value]

        case 'fromStayDate':
          value = dayjs(value).format('L')

          return `À partir du ${value}`
        case 'toStayDate':
          value = dayjs(value).format('L')

          return `Jusqu'au ${value}`

        case 'presentDocumentCategory':
        case 'absentDocumentCategory':

          return documentCategories[value] ?? textualHealthDataCategories[value]

        case 'depBehaviour':
        case 'depCognitive':
        case 'depContinence':
        case 'depDressing':
        case 'depFeeding':
        case 'depMoving':
        case 'depSocial':
        case 'depPhysical':
          value = value.split('_')
          return makeRangeLabel(value[0], value[1], '', Gender.Feminine)

        default:
          return value
      }
    },
    getModifiers(value: string) {
      if (value.indexOf('\x1F') === -1) {
        return {}
      }

      const modifiers = {
        includeAntecedents: false,
        includeFamilyAntecedents: false,
        wordDistance: -1,
      }
      const strModif = value.substring(0, value.indexOf('\x1F'))
      const strModifSplit = strModif.split('_')

      strModifSplit.forEach((modif) => {
        if (modif === 'a') {
          modifiers.includeAntecedents = true
        } else if (modif === 'f') {
          modifiers.includeFamilyAntecedents = true
        } else if (modif.substring(0, 2) === 'wd') {
          modifiers.wordDistance = parseInt(modif.substring(2))
        }
      })

      return modifiers
    },
    removeCriteria(criterion) {
      this.$emit('remove-criteria', { criteriaGroupId: this.criteriaGroup.id, criterion })
    },
    selectDisplayedCriteria(values) {
      if (this.maxCriteria > 0) {
        values = values.slice(0, this.maxCriteria)
      }

      return values.map((criterion) => {
        if (['presentContent', 'absentContent'].indexOf(criterion.type) < 0) {
          return criterion
        }

        criterion.value = criterion.value || criterion.rawContent

        return {
          id: criterion.id,
          type: criterion.type,
          rawContent: criterion.value,
          content: this.renderCriteria(criterion.type, criterion.value),
          modifiers: this.getModifiers(criterion.value)
        }
      })
    },
  }
}
