<script>
import { SancareOcticon, SancareSticky } from '@sancare/ui-frontend-commons'
import dayjs from 'dayjs'
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'

import LineClampToggle from '@/common/LineClampToggle.vue'
import MedicalActList from '@/stay-displayer/mco/medical-act/MedicalActList.vue'
import { CountryEnum } from '@/store/modules/settings/types'
import { ModeEnum } from '@/store/modules/settings/types'
import { formattedHealthData } from '@/store/modules/stay/types'

import AutomationDisabler from './AutomationDisabler.vue'
import DiagnosisListAutomationDemoMode from './DiagnosisListAutomationDemoMode.vue'
import DiagnosisListAutomationMode from './DiagnosisListAutomationMode.vue'
import DiagnosisListStandardMode from './DiagnosisListStandardMode.vue'
import DocumentViewer from './DocumentViewer.vue'
import AsideDrg from './grouping/mco/AsideDrg.vue'
import AsideGhm from './grouping/mco/AsideGhm.vue'
import KeywordButton from './KeywordButton.vue'
import GeneralStayData from './mco/GeneralStayData.vue'
import ReadingListNavigator from './ReadingListNavigator.vue'
import ReportSelector from './ReportSelector.vue'
import RumSelector from './RumSelector.vue'
import StayComment from './StayComment.vue'
import StayReviewator from './StayReviewator.vue'
import StaySynchronizator from './StaySynchronizator.vue'
import StayValidator from './StayValidator.vue'

export default {
  components: {
    'stay-comment': StayComment,
    'general-stay-data': GeneralStayData,
    'report-selector': ReportSelector,
    'document-viewer': DocumentViewer,
    'aside-ghm': AsideGhm,
    'aside-drg': AsideDrg,
    'diagnosis-list-automation-mode': DiagnosisListAutomationMode,
    'diagnosis-list-automation-demo-mode': DiagnosisListAutomationDemoMode,
    'diagnosis-list-standard-mode': DiagnosisListStandardMode,
    'medical-act-list': MedicalActList,
    'rum-selector': RumSelector,
    'sancare-sticky': SancareSticky,
    'stay-reviewator': StayReviewator,
    'stay-synchronizator': StaySynchronizator,
    'stay-validator': StayValidator,
    'automation-disabler': AutomationDisabler,
    'keyword-button': KeywordButton,
    'reading-list-navigator': ReadingListNavigator,
    'sancare-octicon': SancareOcticon,
    'line-clamp-toggle': LineClampToggle,
  },
  beforeRouteUpdate(to, from, next) {
    // unset stayList route if not coming from or going to a readingList
    if (!from.query.readingList || !to.query.readingList) {
      this.stayListPage = null
    }
    // set previous stay route when coming from a chronic justification
    if (to.query.chronicRef) {
      this.stayPage = from.path
    } else {
      this.stayPage = null
    }
    next()
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('resetReadingList')
    if (!from.query.readingList) {
      this.stayListPage = null
    }
    if (!from.query.chronicRef) {
      this.stayPage = null
    }
    next()
  },
  data() {
    return {
      displayedRumId: 0,
      showScrollTop: false,
      selection: null,
      stayIdentifier: window.stayIdentifier,
      stayListPage: null,
      stayPage: null,
      CountryEnum,
      ModeEnum,
      displayAllKeywords: false,
      displayKeywordsToggleButton: true,
    }
  },
  computed: {
    ...mapGetters(['flatAppSettings', 'flatSancareSettings']),
    ...mapState({
      healthData: (state) => formattedHealthData(state.stayDisplayer.displayedStay),
      displayedStay: (state) => state.stayDisplayer.displayedStay,
      mode: (state) => (state.route.query.mode ? state.route.query.mode : ModeEnum.OPTIMIZABLE),
      teamMode: (state) => state.settings.currentUser.teamMode,
      pmsiCountry: (state) => state.settings.pmsi.country,
      staySavedSearches: (state) => state.stayDisplayer.displayedStay.staySavedSearches,
      shouldScroll: (state) => state.stayDisplayer.shouldScroll,
      mainContent: (state) => state.stayDisplayer.mainContent,
      routeStayId: (state) => state.route.params.stayId,
      routeIdType: (state) => state.route.params.idType,
      getStayRequest: (state) => state.stayDisplayer.getStayRequest,
      suggestedChronicDas: (state) => state.stayDisplayer.displayedStay.suggestedChronicDas,
      chronicDasJustificationRequest: (state) => state.stayDisplayer.getChronicDasJustificationRequest,
      selectedChunks(state) {
        if (!this.selection) {
          return null
        }
        const stay = state.stayDisplayer.displayedStay

        // If labelType and labelReference are defined,
        // the chunks can be related to a predictedDiagnosis
        for (const predictedLabel of this.getRumPredictedLabels()) {
          if (predictedLabel.type ===  this.selection.labelReference && (predictedLabel.diagnosis?.reference ?? predictedLabel.medicalAct.reference) === this.selection.labelReference) {
            return predictedLabel.chunks
          }
        }

        // Else, they must be from a savedSearch, or many.
        if (this.selection.savedSearchIds.length === 1) {
          // In this case, either it comes from a regular staySavedSearch, or from an additionnalKeywordSearch
          const searchId = this.selection.savedSearchIds[0]
          if (stay.additionnalKeywordChunks[searchId]) {
            return stay.additionnalKeywordChunks[searchId]
          }
          for (const staySavedSearch of stay.staySavedSearches) {
            if (staySavedSearch.search.id === searchId) {
              return staySavedSearch.chunks
            }
          }
        } else if (this.selection.savedSearchIds.length > 1) {
          for (const searchGroup of stay.savedSearchIntersectionChunks) {
            if (_.isEqual(searchGroup.searchIds, this.selection.savedSearchIds.sort())) {
              return searchGroup.chunks
            }
          }
        }

        return null
      },
    }),
    hasCurrentCoding() {
      return this.displayedStay.rums.some(rum => rum.standardModeDiagnosisGroup.currentDP)
    },
    automationAsideGhmGroupingInfo() {
      const prefix = this.hasCurrentCoding ? 'standard' : 'automation'
      return {
        ghm: this.displayedStay[prefix+'ModeGhm'],
        ghs: this.displayedStay[prefix+'ModeGhs'],
        value: this.displayedStay[prefix+'ModeValue'],
        groupingError: this.displayedStay[prefix+'ModeGroupingError'],
      }
    },
    validatedOn() {
      const d = dayjs(this.displayedStay.validationDate)
      return `le ${d.format('DD/MM/YYYY')} à ${d.format('HH:mm')}`
    },
    validatedBy() {
      return this.displayedStay.validationUser.sancareUser
        ? 'Sancare'
        : `${this.displayedStay.validationUser.firstName} ${this.displayedStay.validationUser.lastName}`
    },
    mostRecentHealthUpdate() {
      const max = _.maxBy(
        [
          { type: 'healthData', label: '[Ajout de données de santé] : ', date: this.displayedStay.lastHealthDataUpdate },
          { type: 'prediction', label: '[Ajout de détections] : ', date: this.displayedStay.lastPredictionUpdate },
          { type: 'external', label: '[Connecteur PMSI] : ', date: this.displayedStay.lastExternalPmsiUpdate }
        ],
        'date')
      return `${max.label} le ${max.date.format('DD/MM/YYYY')} à ${max.date.format('HH:mm')}`
    },
    displayedRum() {
      if (this.displayedRumId === null) {
        return this.displayedStay.rums[0]
      }
      return _.find(this.displayedStay.rums, (rum) => rum.id === this.displayedRumId) || this.displayedStay.rums[0]
    },
    displayedRumIdx() {
      return _.findIndex(this.displayedStay.rums, (rum) => rum.id === this.displayedRumId)
    },
    isStayAutomatable() {
      if (this.displayedStay.hasInitialCoding || !this.displayedStay.automationModeGhm || this.displayedStay.automationModeGhm.cmd === '90' || !this.displayedStay.automationRisk) {
        return false
      }
      // caution: keep this synchronised with backend buildIaAutomationRiskConditions()
      let nonSessionCond = false
      if (this.flatSancareSettings['non_session_automation']) {
        nonSessionCond = ( // non session
          this.displayedStay.stayDuration > 0
          && this.displayedStay.stayDuration <= 2
          && this.displayedStay.automationModeGhm.cmd !== '28'
          && this.displayedStay.automationRisk <= this.flatAppSettings['automation_non_session_financial_risk']
        ) || ( // ambu
          this.displayedStay.automationModeGhm.level === 'J'
          && this.displayedStay.automationRisk <= this.flatAppSettings['automation_ambu_financial_risk']
        ) || ( // 0 day non ambu
          this.displayedStay.stayDuration === 0
          && this.displayedStay.automationModeGhm.level !== 'J'
          && this.displayedStay.automationModeGhm.cmd !== '28'
          && this.displayedStay.automationRisk <= this.flatAppSettings['automation_non_ambu_financial_risk']
        )
      }
      let sessionCond = false
      if (this.flatSancareSettings['session_automation']) {
        sessionCond = this.displayedStay.automationModeGhm.cmd === '28'
          && this.displayedStay.automationRisk <= this.flatAppSettings['automation_session_financial_risk']
      }

      return nonSessionCond || sessionCond
    }
  },
  watch: {
    'displayedStay.id': function() {
      this.displayedRumId = this.displayedStay.rums[0].id
      this.selection = null
      this.$store.commit('updateStayStatuses', {
        stayId: this.displayedStay.id,
        username: this.$store.state.login.username
      })
    },
    'displayedStay.suggestedChronicDas': function() {
      if (this.chronicDasJustificationRequest.fetching || this.displayedStay.suggestedChronicDas.chronicDasList.length === 0) {
        return
      }
      this.$store.dispatch('getChronicDasJustification', this.displayedStay.suggestedChronicDas)
    },
    routeStayId(newRouteStayId) {
      if (!newRouteStayId) {
        return
      }
      this.$store.dispatch(
        'getStay',
        {
          stayId: newRouteStayId,
          idType: this.routeIdType,
          mode: this.mode
        }
      )
    },
    displayedRumId() {
      this.selection = null
    },
    teamMode(next, prev) {
      if (!_.isNull(prev)) {
        this.refresh()
      }
    },
  },
  created() {
    this.handleDebouncedScroll = _.debounce(this.handleScroll, 250)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },
  async mounted() {
    await this.refresh()
    if (this.displayedStay && this.displayedRumId === 0) {
      this.displayedRumId = this.displayedStay.rums[0].id
    }
  },
  methods: {
    selectRum(rumId) {
      this.displayedRumId = rumId
    },
    makeSelection({ labelType, labelReference, savedSearchIds }) {
      if (this.selection && labelType === this.selection.labelType && labelReference === this.selection.labelReference && _.isEqual(savedSearchIds, this.selection.savedSearchIds)) {
        this.selection = null
      } else {
        this.selection = { labelType, labelReference, savedSearchIds: savedSearchIds }
      }
    },
    getRumPredictedLabels() {
      const rum = _.find(this.displayedStay.rums, (rum) => {
        return rum.id === this.displayedRumId
      })
      if (rum) {
        return rum.displayablePredictedLabels || rum.predictedLabels
      }
      return []
    },
    getRumPredictedDiagnoses() {
      return this.getRumPredictedLabels().filter((predictedLabel) => predictedLabel.diagnosis)
    },
    getRumPredictedMedicalActs() {
      return this.getRumPredictedLabels().filter((predictedLabel) => predictedLabel.medicalAct)
    },
    disableJustificationScrolling() {
      this.$store.commit('shouldScroll', false)
    },
    setMainContent(mainContent) {
      this.$store.commit('setMainContent', mainContent)
    },
    refresh() {
      if (this.routeStayId) {
        this.$store.dispatch(
          'getStay',
          {
            stayId: this.routeStayId,
            idType: this.routeIdType,
            mode: this.mode
          }
        )
      }
      if (window.sessionStorage['stayListRoute']) {
        this.stayListPage = {
          path: window.sessionStorage.getItem('stayListRoute'),
          query: { ...this.$store.getters.settingsUrlQuery, ...this.$store.getters.shortHandStayListParams }
        }
      }
    },
    goTopPage() {
      scroll({ top: 0, behavior: 'smooth' })
    },
    handleScroll() {
      this.showScrollTop = window.scrollY > 80
    }
  },
}
</script>

<template>
  <div v-if="displayedStay && getStayRequest.ok">
    <div class="row no-gutters">
      <div
        :class="{
          'offset-xl-1 col-xl-10': mode !== ModeEnum.AUTOMATION,
          'offset-md-1 col-md-10': mode === ModeEnum.AUTOMATION,
        }"
      >
        <reading-list-navigator
          :mode="mode"
        />
        <router-link
          v-if="stayPage"
          name="stayDisplayer_back"
          :to="stayPage"
        >
          &#8592;&nbsp;&nbsp;Retour au séjour précédent
        </router-link>
        <router-link
          v-else-if="stayListPage"
          name="stayDisplayer_back"
          :to="stayListPage"
        >
          &#8592;&nbsp;&nbsp;Retour à la liste des séjours
        </router-link>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col row no-gutters">
        <div
          style="margin-bottom: 45px"
          :class="{
            'offset-xl-1 col-6': mode !== ModeEnum.AUTOMATION,
            'offset-md-1 offset-lg-1 col-7': mode === ModeEnum.AUTOMATION,
          }"
          class="p-2"
        >
          <div
            v-if="displayedStay.status === 'validated'"
            class="col-12 text-center mb-2 alert alert-success"
          >
            Ce séjour a été validé {{ validatedOn }} par {{ validatedBy }}
          </div>
          <general-stay-data
            :stay="displayedStay"
            :displayed-rum-id="displayedRumId"
          />
          <section v-if="displayedStay.additionnalKeywordSearches.length">
            <div class="row">
              <div class="col">
                <span>Mots-clés ({{ displayedStay.additionnalKeywordSearches.length }})</span>
              </div>
            </div>
            <line-clamp-toggle
              :line-clamp="3"
              :is-open="displayAllKeywords"
              @display-toggle-button="displayToggle => displayKeywordsToggleButton = displayToggle"
            >
              <keyword-button
                v-for="(keyword) in displayedStay.additionnalKeywordSearches"
                :key="keyword.search.id"
                :keyword-value="keyword.value"
                :document-type="keyword.documentType"
                :keyword-index="keyword.search.id"
                :is-highlighted="selection && selection.savedSearchIds.indexOf(keyword.search.id) !== -1"
                :selection="selection"
                :health-data="healthData"
                :is-loading="displayedStay.isLoading"
                :chunks="displayedStay.additionnalKeywordChunks[keyword.search.id]"
                :displayed-rum-idx="displayedRumIdx"
                :saved-searches="staySavedSearches"
                :rum-predicted-labels="getRumPredictedLabels()"
                :should-scroll="shouldScroll"
                @make-selection="makeSelection"
                @disable-justification-scrolling="disableJustificationScrolling"
              />
            </line-clamp-toggle>
            <div
              v-if="displayKeywordsToggleButton"
              class="text-right"
            >
              <span
                class="btn btn-sm btn-link"
                @click="displayAllKeywords = !displayAllKeywords"
              >
                <sancare-octicon
                  :name="displayAllKeywords ? 'chevron-up' : 'chevron-down'"
                  :height="12"
                  style="fill:currentColor"
                />
                {{ displayAllKeywords ? 'réduire les mots-clés' : 'afficher tous les mots-clés' }}
              </span>
            </div>
          </section>
          <report-selector
            :health-data="healthData"
            :selected-chunks="selectedChunks"
            :main-content="mainContent"
            @set-main-content="setMainContent"
          />
          <document-viewer
            :current-stay="displayedStay"
            :current-document="mainContent"
            :displayed-rum-idx="displayedRumIdx"
            :selection="selection"
            :rum-predicted-labels="getRumPredictedLabels()"
            :should-scroll="shouldScroll"
            @disable-justification-scrolling="disableJustificationScrolling()"
          />
        </div>
        <sancare-sticky
          :class="{
            'col-6 col-xl-5': mode !== ModeEnum.AUTOMATION,
            'col-5 col-md-4 col-lg-4 col-xl-3': mode === ModeEnum.AUTOMATION,
          }"
          :custom-styles="{'padding-bottom': '45px'}"
          class="p-2"
        >
          <div class="row no-gutters">
            <aside-drg
              v-if="pmsiCountry === CountryEnum.CH"
              :cflag="displayedStay.standardModeDrg ? displayedStay.standardCflag : displayedStay.firstStandardCflag"
              :drg="displayedStay.standardModeDrg ?? displayedStay.firstStandardModeDrg ?? {reference:'E64C', description: 'Insuffisance respiratoire ou embolie pulmonaire, plus d’un jour d’hospitalisation'}"
              :ecw="displayedStay.standardModeDrg ? displayedStay.standardModeValue : displayedStay.firstStandardModeValue"
              :grouping-error="displayedStay.standardModeGroupingError"
              :pccl="displayedStay.standardModeDrg ? displayedStay.standardPccl : displayedStay.firstStandardPccl"
            />
            <aside-ghm
              v-else-if="mode !== ModeEnum.AUTOMATION"
              :mode="mode"
              :ghm="displayedStay.standardModeGhm ? displayedStay.standardModeGhm : displayedStay.firstStandardModeGhm"
              :ghs="displayedStay.standardModeGhm ? displayedStay.standardModeGhs : displayedStay.firstStandardModeGhs"
              :value="displayedStay.standardModeGhm ? displayedStay.standardModeValue : displayedStay.firstStandardModeValue"
              :money-gap="displayedStay.standardModeGhm ? (displayedStay.standardModeValue - displayedStay.firstStandardModeValue) : 0"
              :grouping-error="displayedStay.standardModeGroupingError"
              :stay-duration="displayedStay.stayDuration"
              :is-automatable="isStayAutomatable"
              class="col-5"
            />
            <aside-ghm
              v-else
              :mode="mode"
              v-bind="automationAsideGhmGroupingInfo"
              :stay-duration="displayedStay.stayDuration"
              :is-automatable="isStayAutomatable"
              class="col-12"
            />
          </div>
          <div class="row no-gutters">
            <div
              :class="{
                'col-5': mode !== ModeEnum.AUTOMATION ,
                'col-12': mode === ModeEnum.AUTOMATION,
              }"
            >
              <stay-reviewator
                v-if="displayedStay.status !== 'validated'"
                :stay="displayedStay"
                :mode="mode"
                class="mb-3"
              />
              <stay-synchronizator
                :stay="displayedStay"
                class="mb-3"
              />
              <stay-validator
                v-if="(mode === ModeEnum.AUTOMATION && !flatSancareSettings['demo_mode']) || mode !== ModeEnum.AUTOMATION"
                :stay="displayedStay"
                :searches="displayedStay.staySavedSearches"
                :mode="mode"
                class="mb-3"
              />
              <automation-disabler
                v-if="mode === ModeEnum.AUTOMATION"
                class="mb-3"
                :stay="displayedStay"
              />
            </div>
            <div class="col-2" />
            <span
              v-tooltip="mostRecentHealthUpdate"
              class="icon-blue icon-blue-label row no-gutters"
            >
              <sancare-octicon
                name="stopwatch"
                :width="20"
                :height="20"
              />
              <span class="mx-2">
                Dernière mise à jour
              </span>
            </span>
          </div>
          <diagnosis-list-standard-mode
            v-if="mode !== ModeEnum.AUTOMATION"
            :sancare-settings="flatSancareSettings"
            :app-settings="flatAppSettings"
            :mode="mode"
            :ghm="displayedStay.firstStandardModeGhm"
            :rum-id="displayedRum.id"
            :standard-mode-diagnosis-group="displayedRum.standardModeDiagnosisGroup"
            :automation-mode-diagnosis-group="displayedRum.automationModeDiagnosisGroup"
            :selection="selection"
            :is-loading="displayedStay.isLoading"
            :health-data="healthData"
            :stay-saved-searches="displayedStay.staySavedSearches"
            :standard-mode-excluded-diagnoses="displayedStay.standardModeExcludedDiagnoses"
            :saved-search-intersection-chunks="displayedStay.savedSearchIntersectionChunks"
            :displayed-rum-idx="displayedRumIdx"
            :rum-predicted-labels="getRumPredictedLabels()"
            :suggested-chronic-das="suggestedChronicDas"
            :stay-has-initial-coding="displayedStay.hasInitialCoding"
            @make-selection="makeSelection"
          />
          <diagnosis-list-automation-mode
            v-if="mode === ModeEnum.AUTOMATION && !flatSancareSettings['demo_mode']"
            :app-settings="flatAppSettings"
            :rum-id="displayedRum.id"
            :diagnosis-group="displayedRum.standardModeDiagnosisGroup.currentDP ? displayedRum.standardModeDiagnosisGroup : displayedRum.automationModeDiagnosisGroup"
            :selection="selection"
            :is-loading="displayedStay.isLoading"
            :health-data="healthData"
            :displayed-rum-idx="displayedRumIdx"
            :rum-predicted-labels="getRumPredictedLabels()"
            @make-selection="makeSelection"
          />
          <diagnosis-list-automation-demo-mode
            v-if="mode === ModeEnum.AUTOMATION && flatSancareSettings['demo_mode']"
            :app-settings="flatAppSettings"
            :rum-id="displayedRum.id"
            :standard-mode-diagnosis-group="displayedRum.standardModeDiagnosisGroup"
            :automation-mode-diagnosis-group="displayedRum.automationModeDiagnosisGroup"
            :selection="selection"
            :is-loading="displayedStay.isLoading"
            :health-data="healthData"
            :displayed-rum-idx="displayedRumIdx"
            :rum-predicted-labels="getRumPredictedLabels()"
            @make-selection="makeSelection"
          />
          <stay-comment
            :stay="displayedStay"
            class="mb-3"
          />
          <medical-act-list
            :coded-medical-acts="displayedRum.codedMedicalActs"
            :displayed-rum-idx="displayedRumIdx"
            :health-data="healthData"
            :predicted-medical-acts="getRumPredictedMedicalActs()"
            :pmsi-country="pmsiCountry"
            :rum="displayedRum"
            :rum-predicted-labels="getRumPredictedLabels()"
            :selection="selection"
            @make-selection="makeSelection"
          />
        </sancare-sticky>
      </div>
      <div />
      <div class="col-auto pl-2">
        <rum-selector
          v-if="pmsiCountry !== CountryEnum.CH"
          :mode="mode"
          :rums="displayedStay.rums"
          :displayed-rum-id="displayedRumId"
          @select-rum="selectRum"
        />
      </div>
    </div>
    <div>
      <transition name="simple-fade">
        <div
          v-show="showScrollTop"
          class="bottom-fixed-button"
          @click="goTopPage"
        >
          <sancare-octicon
            name="chevron-up"
            style="display: block;margin: auto;"
            :width="20"
            :height="20"
          />
          Haut de page
        </div>
      </transition>
    </div>
  </div>
  <div
    v-else-if="getStayRequest.error"
    class="row align-items-start"
  >
    <div
      v-if="getStayRequest.error.status === 404"
      class="stay-page-error-message col-auto mx-auto"
    >
      <h1>Le séjour demandé (id: {{ routeStayId }}) n'a pas pu être trouvé.</h1>
      <div>Cette erreur peut être provoquée par les causes suivantes:</div>
      <ul>
        <li>L'identifiant fourni est erroné ou n'existe pas</li>
        <li>L'identifiant fourni est un {{ stayIdentifier === 'administrativeLocalStayId' ? 'numéro de RSS' : 'numéro administratif de séjour' }} alors que l'interface fonctionne avec des {{ stayIdentifier === 'administrativeLocalStayId' ? 'numéros administratifs de séjour' : 'numéros de RSS' }}.</li>
        <li>Le séjour n'a pas encoré été importé par Sancare. (probable si le séjour est récent)</li>
      </ul>
    </div>
    <div
      v-else
      class="stay-page-error-message col-auto mx-auto"
    >
      <h1>Une erreur est survenue...</h1>
      <div>Ce séjour ne peut pas être affiché, car une erreur est survenue lors de la requête.</div>
      <div>Si le problème se répète, n'hésitez pas à envoyer un rapport de bug à Sancare (icône en haut à droite de l'écran)</div>
      <!-- TODO Mettre le modal de rapport de bug directement ici -->
    </div>
  </div>
</template>
