import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import _ from 'lodash'

import Api from '@/misc/api'
import { doesSearchIdExist } from '@/saved-searches/helpers'
import { DEFAULT_SEARCH_ID } from '@/saved-searches/types'
import { getNewStayListOrderParams } from '@/stay-list/stay-list-table/helpers/storeHelper'

import { globalCriteria } from '../filters/FiltersData'
import router from '../router.js'
import { DEFAULTS, defaultState, emptyObj } from './storeDefaultState.js'

const initialUrl = new URL(location.href)
const findSearchGroup = (search, type, groupId) => {
  if (globalCriteria.indexOf(type) > -1) {
    let group = search.globalCriteriaGroup
    if (group) {
      return group
    }
    group = _.cloneDeep(emptyObj().criteriaGroup)
    group.reference = 'tmpGlobal'
    search.globalCriteriaGroup = group
    return group
  }
  // regular search = one group by type
  if (groupId === null) {
    let group = _.find(search.criteriaGroups, (criteriaGroup) => criteriaGroup.criteriaList.length && criteriaGroup.criteriaList[0].type === type)
    if (group) {
      return group
    }
    group = _.cloneDeep(emptyObj().criteriaGroup)
    group.id = search.criteriaGroups.length === 0 ? 1 : (_.maxBy(search.criteriaGroups, 'id').id + 1)
    group.reference = `tmp${String(group.id)}`
    search.criteriaGroups.push(group)
    return group
  }

  let group = _.find(search.criteriaGroups, (criteriaGroup) => criteriaGroup.criteriaList && criteriaGroup.id === groupId)
  if (group) {
    return group
  }
  group = _.cloneDeep(emptyObj().criteriaGroup)
  group.id = search.criteriaGroups.length === 0 ? 1 : (_.maxBy(search.criteriaGroups, 'id').id + 1)
  group.reference = `tmp${String(group.id)}`
  search.criteriaGroups.push(group)
  return group
}
const findCriteriaOrGlobalCriteriaGroup = (state, criteriaGroupId) => {
  // Get the group whether it's global or not
  const groupIndex = _.isEmpty(state.currentSearch.criteriaGroups) ? -1 : _.findIndex(state.currentSearch.criteriaGroups, (g) => g.id === criteriaGroupId)
  const globalGroup = state.currentSearch.globalCriteriaGroup && state.currentSearch.globalCriteriaGroup.id === criteriaGroupId
    ? state.currentSearch.globalCriteriaGroup
    : null

  if (globalGroup === null && groupIndex < 0) {
    return null
  }
  const group = groupIndex > -1 ? state.currentSearch.criteriaGroups[groupIndex] : globalGroup

  if (!_.isObjectLike(group)) {
    return null
  }
  if (!_.isArrayLikeObject(group.criteriaList)) {
    group.criteriaList = []
  }

  return { group, groupIndex }
}
const importStayList = (stays) => _.map(stays, (stay) => {
  return {
    ...stay,
    lastPredictionUpdate: dayjs(stay.lastPredictionUpdate),
    lastHealthDataUpdate: dayjs(stay.lastHealthDataUpdate),
    stayStart: dayjs(stay.stayStart),
    stayEnd: dayjs(stay.stayEnd),
    staySavedSearches: _.filter(stay.staySavedSearches, (staySearch) => {
      return staySearch.search !== null
    }),
  }
})

const buildStayListRequest = (params, search, activeSearches, limits, medicalUnitList) => {
  const request = {
    page: params.currentPage ? params.currentPage - 1 : 0,
    earlyLimit: limits.earlyLimit,
    lateLimit: limits.lateLimit,
    medicalUnits: medicalUnitList,
    pageSize: Number(params.pageSize) || 20,
    showSeen: typeof params.showSeen === 'undefined' ? DEFAULTS().STAYLIST_SEEN : params.showSeen,
    showStatus: params.showStatus || DEFAULTS().STAYLIST_STATUS,
    order: params.order || 'id',
    reverseOrder: params.reverseOrder ? true : false,
    mode: params.mode ? params.mode : 'optimizable',
    pmsiType: search.pmsiType,
    showOnlyNewCoding: params.showOnlyNewCoding || undefined,
    improveRecallMode: params.improveRecallMode || false,
    searchOperators: search.searchOperators,
    isAdvancedSearch: search.isAdvancedSearch,
  }

  if (params.mode === 'optimizable') {
    if (params.searchCreation || doesSearchIdExist(params.searchEdition)) {
      request.revaluationType = 'undetermined'
    } else {
      request.revaluationType = params.revaluationType ? params.revaluationType : 'ia_or_search_das'
    }
  }

  request.globalCriteriaGroup = _.cloneDeep(search.globalCriteriaGroup)
  request.criteriaGroups = _.cloneDeep(search.criteriaGroups)

  if (activeSearches && activeSearches.length && !doesSearchIdExist(params.searchEdition)) {
    request.globalCriteriaGroup.criteriaList = _.concat(request.globalCriteriaGroup.criteriaList, activeSearches.map((activeSearch) => ({ type: 'searchId', value: activeSearch })))
  }

  return request
}

export default {
  state() {
    return { ...defaultState() }
  },
  getters: {
    readingListCurrentStayId(state) {
      if (state.readingList.currentIndex !== null && state.readingList.stayIds.length) {
        return state.readingList.stayIds[state.readingList.currentIndex]
      }
      return null
    },
    shortHandStayListParams(state) {
      const showStayStatus = state.stayListParams.showStatus

      return {
        od: (state.stayListParams.order !== DEFAULTS().STAYLIST_ORDER) ? state.stayListParams.order : undefined,
        ro: state.stayListParams.reverseOrder ? true : undefined,
        cp: (state.stayListParams.currentPage !== DEFAULTS().STAYLIST_PAGE) ? state.stayListParams.currentPage : undefined,
        ps: (state.stayListParams.pageSize !== DEFAULTS().STAYLIST_PAGE_SIZE) ? state.stayListParams.pageSize : undefined,
        ss: state.stayListParams.showSeen ? state.stayListParams.showSeen : undefined,
        sss: _.isEmpty(showStayStatus) || !_.isArray(showStayStatus) ? DEFAULTS().STAYLIST_STATUS : showStayStatus,
        mode: state.stayListParams.mode ? state.stayListParams.mode : DEFAULTS().STAYLIST_MODE,
        revType: state.stayListParams.revaluationType !== DEFAULTS().STAYLIST_REVALUATION_TYPE ? state.stayListParams.revaluationType : undefined,
        newCoding: state.stayListParams.showOnlyNewCoding ? state.stayListParams.showOnlyNewCoding : undefined,
        sc: state.stayListParams.searchCreation ? state.stayListParams.searchCreation : undefined,
        edit: doesSearchIdExist(state.stayListParams.searchEdition) ? state.stayListParams.searchEdition : undefined,
        irm: state.stayListParams.improveRecallMode ? state.stayListParams.improveRecallMode : false,
      }
    },
    buildStandardRequest(state, getters, rootState) {
      return buildStayListRequest(
        state.stayListParams,
        state.currentSearch,
        state.activeSearches,
        { earlyLimit: null, lateLimit: null },
        rootState.settings.selectedMedicalUnits
      )
    },
    isStaySelected(state) {
      return (stayId) => state.selectedStayIds.includes(stayId)
    },
    getStayListExportRequest(state, getters, rootState) {
      return {
        ...getters.buildStandardRequest,
        page: 0,
        pageSize: 9999,
        earlyLimit: rootState.settings.earlyLimit.format(),
        lateLimit: rootState.settings.lateLimit.format(),
      }
    },
  },
  mutations: {
    setInitialCriteriaToCurrentSearch(state) {
      state.initialCriteria = {
        globalCriteriaGroup: state.currentSearch.globalCriteriaGroup,
        criteriaGroups: state.currentSearch.criteriaGroups,
      }
    },
    setInitialCriteriaToStoredCriteria(state) {
      state.initialCriteria = {
        globalCriteriaGroup: state.currentSearch.globalCriteriaGroup,
        criteriaGroups: state.currentSearch.criteriaGroups,
      }
    },
    setStayListRoute(state, route) {
      state.stayListRoute = route
    },
    setStayListParam(state, { type, value }) {
      state.stayListParams[type] = value
    },
    setStayListParams(state, stayListParams) {
      state.stayListParams = stayListParams
    },
    setStayListOrder(state, toBeOrderedColumn) {
      state.stayListParams = {
        ...state.stayListParams,
        ...getNewStayListOrderParams(toBeOrderedColumn, {
          order: state.stayListParams.order,
          reverseOrder: state.stayListParams.reverseOrder
        })
      }
    },
    setStayListInitialized(state) {
      state.initialized.stayList = true
    },
    setStayListParamsInitialized(state) {
      state.initialized.stayListParams = true
    },
    setStayListCriteriaInitialized(state) {
      state.initialized.stayListCriteria = true
    },
    setActiveSearches(state, searchIds) {
      state.activeSearches = searchIds
    },

    loadSavedSearch(state, search) {
      if (!search) {
        state.activeSearches = []

        return
      }

      // if we're editing a staylist, keep criteria taken from store
      const initialCriteriaGroupsSet = state.initialCriteria.criteriaGroups && state.initialCriteria.criteriaGroups.length > 0
      const initialGlobalCriteriaGroupsSet = state.initialCriteria.globalCriteriaGroup && state.initialCriteria.globalCriteriaGroup.criteriaList > 0
      if (doesSearchIdExist(state.stayListParams.searchEdition)
        && (initialCriteriaGroupsSet || initialGlobalCriteriaGroupsSet)) {
        search.globalCriteriaGroup = state.initialCriteria.globalCriteriaGroup
        search.criteriaGroups = state.initialCriteria.criteriaGroups
      }
      state.currentSearch = _.cloneDeep(search)
      if (doesSearchIdExist(state.stayListParams.searchCopyFrom)) {
        state.currentSearch.id = DEFAULT_SEARCH_ID
        state.currentSearch.user = null
        state.currentSearch.privacy = 'privateSearch'
      }
      state.currentSearchEdited = false
      state.editionInProgress = false
      state.currentSearch.savedSearchCategory = search.savedSearchCategory
      state.activeSearches = []
    },

    // Both regular & advanced search mutations
    clearCurrentSearchCriteria(state) {
      state.currentSearch = {
        ...state.currentSearch,
        globalCriteriaGroup: emptyObj().savedSearch.globalCriteriaGroup,
        criteriaGroups: emptyObj().savedSearch.criteriaGroups,
      }
      state.currentSearchEdited = doesSearchIdExist(state.stayListParams.searchEdition) || doesSearchIdExist(state.stayListParams.searchCreation)
      state.editionInProgress = doesSearchIdExist(state.stayListParams.searchEdition) || doesSearchIdExist(state.stayListParams.searchCreation)

      state.stayListParams.currentPage = DEFAULTS().STAYLIST_PAGE
    },
    resetStayList(state) {
      // Full reset of all this store from the defaultState
      Object.assign(state, defaultState())

      return state
    },
    resetStoredCriteria(state) {
      state.storedCriteria = _.cloneDeep(emptyObj().storedCriteria)
    },
    setEditionInProgress(state, editionInProgress) {
      state.editionInProgress = editionInProgress
    },
    setCurrentSearch(state, currentSearch) {
      if (_.isEmpty(currentSearch.criteriaGroups)) {
        currentSearch.criteriaGroups = _.cloneDeep(defaultState().currentSearch.criteriaGroups)
      }
      if (_.isEmpty(currentSearch.globalCriteriaGroup)) {
        currentSearch.globalCriteriaGroup = _.cloneDeep(defaultState().currentSearch.globalCriteriaGroup)
      }

      state.currentSearch = currentSearch
    },
    setStoredCriteria(state, criteria) {
      state.storedCriteria = criteria
    },
    setSearchName(state, name) {
      state.currentSearch.name = name
      state.storedCriteria.name = name
      state.editionInProgress = true
    },
    setSearchPrivacy(state, privacy) {
      state.currentSearch.privacy = privacy
      state.storedCriteria.privacy = privacy
      state.editionInProgress = true
    },
    setSearchCategory(state, category) {
      state.currentSearch.savedSearchCategory.name = category
      state.storedCriteria.savedSearchCategory.name = category
      state.editionInProgress = true
    },

    // Regular search mutations
    addRegularCriteria(state, { type, value, criteriaGroupId }) {
      let oppositeType = null
      if (type.indexOf('present') !== -1) {
        oppositeType = type.replace('present', 'absent')
      } else if (type.indexOf('absent') !== -1) {
        oppositeType = type.replace('absent', 'present')
      }

      const group = findSearchGroup(state.currentSearch, type, criteriaGroupId)
      if (!_.some(group.criteriaList, (criterion) => {
        return type === criterion.type && value === criterion.value
      })) {
        group.criteriaList.push({ type, value, id: _.random(Math.pow(2, 32) - 1) })
        group.criteriaList = _.filter(group.criteriaList, (criterion) => {
          return oppositeType !== criterion.type
            || (oppositeType === criterion.type && value !== criterion.value)
        })
      }

      state.currentSearchEdited = true
      state.editionInProgress = true
      state.stayListParams.currentPage = 1
    },
    replaceRegularCriteria(state, { type, value, criteriaGroupId }) {
      const group = findSearchGroup(state.currentSearch, type, criteriaGroupId)

      const toBeReplacedCriterion = _.find(group.criteriaList, (criterion) => criterion.type === type)
      if (!toBeReplacedCriterion) {
        group.criteriaList.push({ type: type, value: value })
      } else {
        toBeReplacedCriterion.value = value
      }

      state.currentSearchEdited = true
      state.editionInProgress = true
      state.stayListParams.currentPage = 1
    },
    // Advanced search mutations
    setSearchIsAdvanced(state, isAdvanced) {
      state.currentSearch.isAdvancedSearch = isAdvanced
      state.storedCriteria.isAdvancedSearch = isAdvanced
    },
    setSearchPmsiType(state, pmsiType) {
      state.currentSearch.pmsiType = pmsiType
      state.storedCriteria.pmsiType = pmsiType
    },
    initiateGlobalCriteriaGroup(state) {
      state.currentSearch.globalCriteriaGroup = { ..._.cloneDeep(emptyObj().criteriaGroup), id: _.uniqueId('frontend_') }
      state.currentSearchEdited = true
    },
    addCriteriaGroup(state) {
      const newCriteriaGroup = { ..._.cloneDeep(emptyObj().criteriaGroup), id: _.uniqueId('frontend_') }
      state.currentSearch.criteriaGroups = [...state.currentSearch.criteriaGroups, newCriteriaGroup]
      state.currentSearchEdited = true
    },
    removeCriteriaGroup(state, id) {
      state.currentSearch.criteriaGroups = _.filter(state.currentSearch.criteriaGroups, (group) => group.id !== id)
      state.currentSearchEdited = true
      state.editionInProgress = true
    },
    updateOperators(state, operatorSystem) {
      state.currentSearch.searchOperators = operatorSystem
      state.currentSearchEdited = true
      state.editionInProgress = true
    },
    addAdvancedCriteria(state, { criteriaGroupId, type, value }) {
      const { group } = findCriteriaOrGlobalCriteriaGroup(state, criteriaGroupId)
      if (group === null) {
        return
      }
      group.criteriaList.push({ type, value, id: _.random(Math.pow(2, 32) -1) })
      state.currentSearchEdited = true
      state.editionInProgress = true
    },
    removeAdvancedCriteria(state, { criteriaGroupId, criterion }) {
      const { group } = findCriteriaOrGlobalCriteriaGroup(state, criteriaGroupId)
      if (group === null) {
        return
      }
      group.criteriaList = group.criteriaList.filter((c) => c.id !== criterion.id)
      state.currentSearchEdited = true
      state.editionInProgress = true
    },

    fetchStayListStart(state) {
      state.fetchStayListRequest = { fetching: true, error: null, ok: false }
    },
    fetchStayListSuccess(state, response) {
      state.fetchStayListRequest = { fetching: false, error: null, ok: true }
      state.stayList = {
        items: importStayList(response.content),
        pageCount: response._hateoas.pageCount,
        totalCount: response._hateoas.filteredItemCount,
        precision: response._hateoas.precision !== undefined ? response._hateoas.precision : null,
        recall: response._hateoas.recall !== undefined ? response._hateoas.recall : null,
        selected: response._hateoas.selected !== undefined ? response._hateoas.selected : null,
        totalMatching: response._hateoas.totalMatching !== undefined ? response._hateoas.totalMatching : null,
        expected: response._hateoas.expected !== undefined ? response._hateoas.expected : null,
      }
    },
    fetchStayListError(state, res) {
      state.fetchStayListRequest = { fetching: false, error: res.message, ok: false }
      state.stayList = {
        items: [],
        pageCount: 1,
        totalCount: 0,
        precision: null,
        recall: null,
        selected : null,
        totalMatching : null,
        expected : null
      }
    },
    resetStaySelection(state) {
      state.selectedStayIds = defaultState().selectedStayIds
    },
    addStayToSelection(state, stayId) {
      state.selectedStayIds.push(stayId)
    },
    removeStayFromSelection(state, stayId) {
      state.selectedStayIds = state.selectedStayIds.filter((selectedStayId) => selectedStayId !== stayId)
    },
    resetReadingList(state) {
      // state.currentSearch = _.cloneDeep(emptyObj().savedSearch);
      state.readingList = defaultState().readingList
    },
    createReadingListStart(state) {
      state.createReadingListRequest = { fetching: true, error: null, ok: false }
    },
    createReadingListSuccess(state, res) {
      state.createReadingListRequest.fetching = false
      state.createReadingListRequest.ok = true
      state.readingList = res
    },
    createReadingListError(state, res) {
      state.createReadingListRequest.fetching = false
      state.createReadingListRequest.error = res.message
      state.readingList = { currentIndex: null, stayIds: [], status: null }
    },
    updateReadingListStart(state) {
      state.updateReadingListRequest = { fetching: true, error: null, ok: false }
    },
    updateReadingListSuccess(state, res) {
      state.updateReadingListRequest.fetching = false
      state.updateReadingListRequest.ok = true
      state.readingList = res
    },
    updateReadingListError(state, res) {
      state.updateReadingListRequest.fetching = false
      state.updateReadingListRequest.error = res.message
      state.readingList = { currentIndex: null, stayIds: [], status: null }
    },
    getCurrentReadingListStart(state) {
      state.getCurrentReadingListRequest = { fetching: true, error: null, ok: false }
    },
    getCurrentReadingListSuccess(state, res) {
      state.getCurrentReadingListRequest.fetching = false
      state.getCurrentReadingListRequest.ok = true
      state.readingList = res
    },
    getCurrentReadingListError(state, res) {
      state.getCurrentReadingListRequest.fetching = false
      state.getCurrentReadingListRequest.error = res.message
      state.readingList = { currentIndex: null, stayIds: [], status: null }
    },
    validateAutomatableListStart(state) {
      state.validateAutomatableListRequest = { fetching: true, error: null, ok: false }
    },
    validateAutomatableListSuccess(state) {
      state.validateAutomatableListRequest.fetching = false
      state.validateAutomatableListRequest.ok = true
      // we also reset the current readingList
      state.readingList = { currentIndex: null, stayIds: [], status: null }
    },
    validateAutomatableListError(state, res) {
      state.validateAutomatableListRequest.fetching = false
      state.validateAutomatableListRequest.error = res.message
    },
    validateAutomatableSelectionStart(state) {
      state.validateAutomatableSelectionRequest = { fetching: true, error: null, ok: false }
    },
    validateAutomatableSelectionSuccess(state) {
      state.validateAutomatableSelectionRequest.fetching = false
      state.validateAutomatableSelectionRequest.ok = true
      // we also reset the current readingList
      state.readingList = { currentIndex: null, stayIds: [], status: null }
    },
    validateAutomatableSelectionError(state, res) {
      state.validateAutomatableSelectionRequest.fetching = false
      state.validateAutomatableSelectionRequest.error = res.message
    },
    switchToEditSavedSearch(state, savedSearch) {
      state.stayListParams.searchCreation = false
      state.stayListParams.searchEdition = savedSearch.id
      state.currentSearch.id = savedSearch.id
      state.currentSearch.user = savedSearch.user
    },
    updateStayStatuses(state, { stayId, username }) {
      const currentStay = _.find(state.stayList.items, (item) => item.id === stayId)
      if (currentStay) {
        currentStay.statuses.push({
          notification: 0,
          statusValue: 0,
          user: {
            username: username
          }
        })
      }
    },
  },
  actions: {
    saveColumnSelection({ commit }, columnList) {
      return Api.post(
        {
          url: '/api/stays/column-selection',
          body: columnList,
          startMut: 'saveColumnSelectionStart',
          successMut: 'saveColumnSelectionSuccess',
          errorMut: 'savedColumnSelectionError',
        },
        commit
      )
    },
    fetchStayList({ commit, state, rootState }, resetStaySelection) {
      if (resetStaySelection) {
        commit('resetStaySelection')
      }

      const request = buildStayListRequest(
        state.stayListParams,
        state.currentSearch,
        state.activeSearches,
        { earlyLimit: rootState.settings.earlyLimit.format(), lateLimit: rootState.settings.lateLimit.format() },
        rootState.settings.selectedMedicalUnits
      )

      return Api.post(
        {
          url: '/api/stays/filtered-search',
          body: request,
          startMut: 'fetchStayListStart',
          successMut: 'fetchStayListSuccess',
          errorMut: 'fetchStayListError',
        },
        commit
      )
    },
    createReadingList({ commit, state }) {
      const stayIds = state.selectedStayIds
      commit('resetStaySelection')

      return Api.post(
        {
          url: '/api/reading-list',
          body: { stayIds },
          startMut: 'createReadingListStart',
          successMut: 'createReadingListSuccess',
          errorMut: 'createReadingListError',
        },
        commit
      )
    },
    navigateReadingList({ commit }, newIndex) {
      return Api.post(
        {
          url: `/api/reading-list/status?newIndex=${newIndex}`,
          body: '',
          startMut: 'updateReadingListStart',
          successMut: 'updateReadingListSuccess',
          errorMut: 'updateReadingListError',
        },
        commit
      )
    },
    getCurrentReadingList({ commit }) {
      return Api.get(
        {
          url: '/api/reading-list/status',
          startMut: 'getCurrentReadingListStart',
          successMut: 'getCurrentReadingListSuccess',
          errorMut: 'getCurrentReadingListError',
        },
        commit
      )
    },
    validateAutomatableList({ commit, rootState }, { automationGroupId, mode }) {
      const request = {
        automationGroupId: automationGroupId,
        mode: mode,
        params : {
          earlyLimit: rootState.settings.earlyLimit.format(),
          lateLimit: rootState.settings.lateLimit.format(),
        }
      }

      return Api.post(
        {
          url: '/api/automation-stays/validate-automatables-list',
          body: request,
          startMut: 'validateAutomatableListStart',
          successMut: 'validateAutomatableListSuccess',
          errorMut: 'validateAutomatableListError',
        },
        commit
      )
    },
    validateAutomatableSelection({ commit }, { automationGroupId, stayIds }) {
      commit('resetStaySelection')
      return Api.post(
        {
          url: '/api/automation-stays/validate-automatables-selection',
          body: { automationGroupId, stayIds },
          startMut: 'validateAutomatableSelectionStart',
          successMut: 'validateAutomatableSelectionSuccess',
          errorMut: 'validateAutomatableSelectionError',
        },
        commit
      )
    },

    // New (Advanced)Search & staylist functions
    // Helpers
    // To save currentSearch params as an 'unchanged' version of the search
    currentSearchToStoredCriteria({ commit, state }, storedCriteria) {
      const defaultCurrentToStoredMapping = {
        criteriaGroups: state.currentSearch.criteriaGroups,
        searchOperators: state.currentSearch.searchOperators,
        globalCriteriaGroup: state.currentSearch.globalCriteriaGroup,
        isAdvancedSearch: state.currentSearch.isAdvancedSearch,
        name: state.currentSearch.name,
        savedSearchCategory: state.currentSearch.savedSearchCategory,
      }
      const newStoredCriteria = typeof storedCriteria === 'object' ? storedCriteria : defaultCurrentToStoredMapping

      commit('setStoredCriteria', { ...state.storedCriteria, ...newStoredCriteria })
    },
    storedCriteriaToCurrentSearch({ commit, state }, currentSearch) {
      const defaultStoredToCurrentMapping = {
        criteriaGroups: state.storedCriteria.criteriaGroups,
        searchOperators: state.storedCriteria.searchOperators,
        globalCriteriaGroup: state.storedCriteria.globalCriteriaGroup,
        isAdvancedSearch: state.storedCriteria.isAdvancedSearch,
        name: state.storedCriteria.name,
        savedSearchCategory: state.storedCriteria.savedSearchCategory,
        type: state.stayListParams.mode,
      }
      const newCurrentSearch = typeof currentSearch === 'object' ? currentSearch : defaultStoredToCurrentMapping

      commit('setCurrentSearch', { ...state.currentSearch, ...newCurrentSearch })
    },

    // Features
    async initStayList({ commit, dispatch, state, rootState }) {
      if(state.initialized.stayList) {
        return
      }
      await dispatch('initStayListParamsFromEmpty')
      await dispatch('initStayListCriteriaFromEmpty')

      const searchEdition = state.stayListParams.searchEdition ? parseInt(state.stayListParams.searchEdition) : DEFAULT_SEARCH_ID
      const isSearchBeingEdited = doesSearchIdExist(searchEdition)
      const searchCopyFrom = state.stayListParams.searchCopyFrom ? parseInt(state.stayListParams.searchCopyFrom) : DEFAULT_SEARCH_ID
      const isSearchBeingCopied = doesSearchIdExist(searchCopyFrom)
      const earlyLimit = rootState.settings.earlyLimit
      const lateLimit = rootState.settings.lateLimit
      const searchIds = [searchEdition, searchCopyFrom]
        .filter((elem) => {
          return elem && elem !== -1
        })

      if (searchIds.length) {
        await dispatch('fetchSavedSearches', { earlyLimit, lateLimit, pmsiType: rootState.settings.pmsi.pmsiType, searchIds })
      }
      if (isSearchBeingEdited || isSearchBeingCopied) {
        commit('loadSavedSearch', _.find(rootState.savedSearches.savedSearches, (s) => s.id === (!isSearchBeingEdited ? searchCopyFrom : searchEdition)))
      }
      dispatch('refreshStayList', { resetStaySelection: true })
      commit('setStayListInitialized')
    },
    async initStayListParamsFromEmpty({ commit, state, rootState }) {
      if(state.initialized.stayListParams) {
        return
      }
      const routeQuery = rootState.route.query
      const showStatus = initialUrl.searchParams.getAll('sss')
      const params = {
        order: routeQuery.od ? routeQuery.od : DEFAULTS().STAYLIST_ORDER,
        reverseOrder: Boolean(routeQuery.ro),
        currentPage: routeQuery.cp ? parseInt(routeQuery.cp) : DEFAULTS().STAYLIST_PAGE,
        pageSize: routeQuery.ps ? routeQuery.ps : DEFAULTS().STAYLIST_PAGE_SIZE,
        showSeen: typeof routeQuery.ss !== 'undefined' ? (!(routeQuery.ss === 'false' || routeQuery.ss === false)) : DEFAULTS().STAYLIST_SEEN,
        showStatus: _.isEmpty(showStatus) || !_.isArray(showStatus) ? DEFAULTS().STAYLIST_STATUS : showStatus,
        mode: routeQuery.mode ? routeQuery.mode : DEFAULTS().STAYLIST_MODE,
        revaluationType: routeQuery.revType ? routeQuery.revType : DEFAULTS().STAYLIST_REVALUATION_TYPE,
        showOnlyNewCoding: (routeQuery.newCoding === true || routeQuery.newCoding === 'true'),
        searchCreation: Boolean(routeQuery.sc),
        searchEdition: routeQuery.edit ? parseInt(routeQuery.edit) : DEFAULT_SEARCH_ID,
        searchCopyFrom: routeQuery.copy ? parseInt(routeQuery.copy) : DEFAULT_SEARCH_ID,
        improveRecallMode: (routeQuery.irm === true || routeQuery.irm === 'true'),
      }

      let searchIds = routeQuery.searchId

      if (searchIds) {
        searchIds = _.map(
          Array.isArray(searchIds) ? searchIds : [searchIds],
          (searchId) => parseInt(searchId)
        )
      }

      await commit('setStayListParams', params)
      await commit('setActiveSearches', searchIds)
      commit('setStayListParamsInitialized')
    },
    initStayListCriteriaFromEmpty({ commit, dispatch, state }) {
      if(state.initialized.stayListCriteria) {
        return
      }
      dispatch('storedCriteriaToCurrentSearch')
      commit('setInitialCriteriaToStoredCriteria')
      commit('setStayListCriteriaInitialized')
    },
    updateStayListOrder({ commit, dispatch }, toBeOrderedColumn) {
      commit('setStayListOrder', toBeOrderedColumn)
      dispatch('refreshStayList', { resetStaySelection: false })
      dispatch('refreshStayListUrl')
    },
    async refreshStayList({ dispatch, state }, { resetStaySelection }) {
      if(state.fetchStayListRequest.fetching) {
        return
      }
      await dispatch('fetchStayList', resetStaySelection)
    },
    async refreshStayListUrl({ dispatch, state, getters, rootState }) {
      const params = {
        ...getters.shortHandStayListParams,
        ...getters.settingsUrlQuery,
      }
      let searches
      if (params.edit > 0) {
        searches = { edit: params.edit }
      } else if (state.activeSearches && state.activeSearches.length) {
        searches = { searchId: state.activeSearches }
      }
      await dispatch('currentSearchToStoredCriteria')
      router.replace(
        {
          path: rootState.route.path,
          query: { ...params, ...searches }
        }
      )
    },
    async resetEdition({ dispatch, state, rootState, commit }) {
      let refreshUrl = false
      if (doesSearchIdExist(state.stayListParams.searchEdition)) {
        refreshUrl = state.currentSearchEdited
        await commit(
          'loadSavedSearch',
          _.find(rootState.savedSearches.savedSearches, (s) => s.id === state.stayListParams.searchEdition)
        )
      }
      if (state.stayListParams.searchCreation) {
        refreshUrl = state.currentSearch.criteriaGroups.length > 0 || state.currentSearch.globalCriteriaGroup.criteriaList.length > 0
        await commit('clearCurrentSearchCriteria')
      }
      await dispatch('refreshStayList', { resetStaySelection: true })
      if (!refreshUrl) {
        return
      }
      dispatch('refreshStayListUrl', {})
    },
    toggleSelectedStay({ getters, commit }, stayId) {
      if (!getters.isStaySelected(stayId)) {
        commit('addStayToSelection', stayId)
      } else {
        commit('removeStayFromSelection', stayId)
      }
    },
  },
}
