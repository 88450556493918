/* Filter Configuration file
 *
 * filters informations :
 *   for each filter you must have :
 *     a name   : specify the filter name, use as value in filter selector option
 *     a type   : specify the filter type, it's explain after.
 *     a label  : specify the filter label, use as innerHTML in filter selector option
 *     a format : specify how to build filter type with subfilters params
 *     a subFilters: Array that can contain ( or not ) more usable sub filters
 *   each filter can have additional parameters base on it's type
 *
 * filters type informations :
 *  [completion]
 *     decription: filter with suggestion list based on typed user value
 *     params:
 *       name: in lowercase will be send to searchControllers
 *  [confidence]
 *     filter for confidence, has it's own type cause it's note a regular case
 *  [free]
 *     description : free text input filter
 *  [range]
 *     description: range filter
 *     params:
 *       less: specify if value can be less than min
 *       min: minimum range value
 *       interval: specify interval between step in the slider
 *       max: maximum range value
 *       more: specify if value can me more than max
 *       unit: specify unity
 *  [remoteList]
 *    description: select based on database value
 *    params:
 *      listPath: name send to search controller to get the list
 *      listKey: field name of the object to use for value
 *  [select]
 *    description: select fix value
 *    params:
 *      options: array with each option value
 *      default: default select value
 *      labels: object that specify labels for each key, if one is not present value will be use as label
 *
 */
import {
  AdmissionModeCh,
  AdmissionModeFr,
  ReleaseModeCh,
  ReleaseModeFr
} from '@sancare/ui-frontend-commons/src/types/admission-release-mode'
import { DestinationFr, OriginFr } from '@sancare/ui-frontend-commons/src/types/origin-destination'

import { documentCategories, textualHealthDataCategories } from '@/stay-displayer/health-data/documentLabels'
import { AutomationTypeEnum } from '@/store/modules/automation/types'
import { FilterData, FilterDataTypeEnum, FilterValueTypeEnum } from '@/store/modules/filters/types'
import { CountryEnum, ModeEnum, PmsiTypeEnum } from '@/store/modules/settings/types'

export const globalCriteria = ['revaloDP', 'revaloDAS', 'softAbsentContent', 'qaAbsentDP', 'qaAbsentDR', 'qaAbsentDAS', 'qaAbsentMedicalAct', 'exclusiveMedicalAct']

const createOptionsArray = (values: { [key: string]: string }, country: CountryEnum) => {
  return Object.keys(values).map((key) => ({
    value: key,
    label: values[key],
    country,
  }))
}

export default {
  content: {
    name: 'content',
    type: FilterDataTypeEnum.TEXT_CONTENT,
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    isGlobalCondition: 'both',
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Recherche Textuelle',
    country: [CountryEnum.CH, CountryEnum.FR],
    subFilters: [
      {
        name: 'presence',
        type: FilterDataTypeEnum.SELECT,
        options: ['present', 'absent', 'softAbsent'],
        isGlobalOptions: ['softAbsent'],
        notGlobalOptions: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Mots-clés présents',
          absent: 'Passages exclus',
          softAbsent: 'Passages exclus des mots-clés'
        }
      }
    ]
  },
  title: {
    name: 'title',
    type: FilterDataTypeEnum.TEXT,
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Titre du Document',
    country: [CountryEnum.CH, CountryEnum.FR],
    subFilters: []
  },
  healthConstant: {
    name: 'healthConstant',
    type: FilterDataTypeEnum.CONSTANT,
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Constante',
    country: [CountryEnum.CH, CountryEnum.FR],
    format: '{part}HealthConstant',
    subFilters: [
      {
        name: 'part',
        type: FilterDataTypeEnum.SELECT,
        options: ['equal_to', 'greater_than', 'less_than'],
        default: 'equal',
        labels: {
          equal_to: 'Egale à',
          greater_than: 'Supérieure à',
          less_than: 'Inférieure à',
        }
      }
    ]
  },
  drug: {
    name: 'drug',
    type: FilterDataTypeEnum.COMPLETION,
    completionName: 'drug',
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Medicaments',
    country: [CountryEnum.CH, CountryEnum.FR],
    format: '{presence}Drug',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataTypeEnum.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
    ],
  },
  biology: {
    name: 'biology',
    type: FilterDataTypeEnum.BIOLOGY,
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Données Biologiques',
    country: [CountryEnum.CH, CountryEnum.FR],
    format: 'biology',
  },
  diagnosis: {
    name: 'diagnosis',
    type: FilterDataTypeEnum.COMPLETION,
    completionName: 'diagnosis',
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    mode: [ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    automationTypes: [],
    label: 'Diagnostics',
    country: [CountryEnum.CH, CountryEnum.FR],
    format: '{presence}{etabType}{diagType}Diagnosis',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataTypeEnum.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
      {
        name: 'etabType',
        type: FilterDataTypeEnum.SELECT,
        options: ['etab', 'sancare', 'both'],
        default: 'etab',
        labels: {
          etab: 'Etablissement',
          sancare: 'Sancare',
          both: 'L\'un ou l\'autre',
        },
      },
      {
        pmsiTypes: [PmsiTypeEnum.MCO],
        name: 'diagType',
        type: FilterDataTypeEnum.SELECT,
        options: ['global', 'principal', 'related', 'associated'],
        default: 'global',
        isTranslated: true,
        labels: {
          global: 'diagnosis.all',
          principal: 'diagnosis.DP',
          related: 'diagnosis.DR',
          associated: 'diagnosis.DAS',
        },
      },
      {
        pmsiTypes: [PmsiTypeEnum.SSR],
        name: 'diagType',
        type: FilterDataTypeEnum.SELECT,
        options: ['global', 'MMP', 'AE', 'DAS'],
        default: 'global',
        isTranslated: true,
        labels: {
          global: 'diagnosis.all',
          MMP: 'diagnosis.MMP',
          AE: 'diagnosis.AE',
          DAS: 'diagnosis.DAS',
        },
      },
    ],
  },
  revaloDiagnosis: {
    name: 'revaloDiagnosis',
    type: FilterDataTypeEnum.COMPLETION,
    completionName: 'diagnosis',
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    isGlobalCondition: 'true',
    mode: [ModeEnum.OPTIMIZABLE],
    automationTypes: [],
    pmsiTypes: [PmsiTypeEnum.MCO], // https://app.clickup.com/t/2628858/WEB-2999
    label: 'Diagnostic Revalorisant',
    country: [CountryEnum.FR],
    format: 'revalo{diagType}',
    subFilters: [
      {
        name: 'diagType',
        type: FilterDataTypeEnum.SELECT,
        // options: ['DAS', 'DP], // hide DP for now (UI-3194), see https://app.clickup.com/t/2628858/UI-3340
        options: ['DAS'],
        default: 'DAS',
        labels: {
          DP: 'DP',
          DAS: 'DAS',
        },
      },
    ],
  },
  qaAbsentDiagnosis: {
    name: 'qaAbsentDiagnosis',
    type: FilterDataTypeEnum.COMPLETION,
    completionName: 'diagnosis',
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    isGlobalCondition: 'true',
    mode: [ModeEnum.QUALITY_STAYS],
    automationTypes: [],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Diagnostic à suggérer',
    country: [CountryEnum.CH, CountryEnum.FR],
    format: 'qaAbsent{diagType}',
    subFilters: [
      {
        pmsiTypes: [PmsiTypeEnum.MCO],
        name: 'diagType',
        type: FilterDataTypeEnum.SELECT,
        options: ['DAS', 'DP', 'DR'],
        default: 'DAS',
        labels: {
          DAS: 'DAS',
          DP: 'DP',
          DR: 'DR',
        },
      },
      {
        pmsiTypes: [PmsiTypeEnum.SSR],
        name: 'diagType',
        type: FilterDataTypeEnum.SELECT,
        options: ['DAS', 'MMP', 'AE'],
        default: 'DAS',
        labels: {
          DAS: 'DAS',
          MMP: 'MMP',
          AE: 'AE',
        },
      },
    ],
  },
  stayDate: {
    name: 'stayDate',
    type: FilterDataTypeEnum.DATE,
    valueType: FilterValueTypeEnum.SINGLE_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Date du séjour',
    country: [CountryEnum.CH, CountryEnum.FR],
    format: '{dateType}StayDate',
    subFilters: [
      {
        name: 'dateType',
        type: FilterDataTypeEnum.SELECT,
        options: ['from', 'to'],
        default: 'from',
        labels: {
          from: 'À partir du',
          to: 'Jusqu\'au',
        },
      },
    ],
  },
  compareDiagnosis: {
    name: 'compareDiagnosis',
    type: FilterDataTypeEnum.SELECT,
    valueType: FilterValueTypeEnum.SINGLE_VALUE,
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.NEW_CODING, ModeEnum.QUALITY_STAYS],
    automationTypes: [],
    pmsiTypes: [PmsiTypeEnum.MCO],
    label: 'Comparaison de DP',
    country: [CountryEnum.CH, CountryEnum.FR],
    options: ['identical', 'different'],
    default: '',
    format: 'compareDiagnosis',
    labels: {
      identical: 'Identique',
      different: 'Différent',
    },
  },
  medicalActActivity: {
    name: 'medicalActActivity',
    type: FilterDataTypeEnum.SELECT,
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Activité d\'acte CCAM',
    country: [CountryEnum.FR],
    options: ['1', '2', '3', '4', '5'],
    default: '',
    format: 'medicalActActivity',
    labels: {
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5'
    },
  },
  admissionMode: {
    name: 'admissionMode',
    type: FilterDataTypeEnum.SELECT,
    valueType: FilterValueTypeEnum.SINGLE_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Mode d\'admission',
    country: [CountryEnum.CH, CountryEnum.FR],
    options: [
      ...createOptionsArray(AdmissionModeFr, CountryEnum.FR),
      ...createOptionsArray(AdmissionModeCh, CountryEnum.CH),
    ],
    optionsLocalised: true,
    default: '',
    format: 'admissionMode',
  },
  releaseMode: {
    name: 'releaseMode',
    type: FilterDataTypeEnum.SELECT,
    valueType: FilterValueTypeEnum.SINGLE_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Mode de sortie',
    country: [CountryEnum.CH, CountryEnum.FR],
    options: [
      ...createOptionsArray(ReleaseModeFr, CountryEnum.FR),
      ...createOptionsArray(ReleaseModeCh, CountryEnum.CH),
    ],
    optionsLocalised: true,
    default: '',
    format: 'releaseMode',
  },
  medicalAct: {
    name: 'medicalAct',
    type: FilterDataTypeEnum.COMPLETION,
    completionName: 'medicalAct',
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'medicalAct.title',
    country: [CountryEnum.CH, CountryEnum.FR],
    isTranslated: true,
    isPlurial: true,
    format: '{presence}MedicalAct',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataTypeEnum.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
    ],
  },
  qaAbsentMedicalAct: {
    name: 'qaAbsentMedicalAct',
    type: FilterDataTypeEnum.COMPLETION,
    completionName: 'medicalAct',
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    isGlobalCondition: 'true',
    mode: [ModeEnum.QUALITY_STAYS],
    automationTypes: [],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    isTranslated: true,
    isPlurial: true,
    label: 'filter.labels.qaAbsentMedicalAct',
    country: [CountryEnum.CH],
    format: 'qaAbsentMedicalAct',
  },
  exclusiveMedicalAct: {
    name: 'exclusiveMedicalAct',
    type: FilterDataTypeEnum.COMPLETION,
    completionName: 'medicalAct',
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    isGlobalCondition: 'true',
    // mode: [ModeEnum.AUTOMATION],
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Actes CCAM exclusifs',
    country: [CountryEnum.CH, CountryEnum.FR],
    format: 'exclusiveMedicalAct',
  },
  startingGhm: {
    name: 'startingGhm',
    type: FilterDataTypeEnum.COMPLETION,
    completionName: 'ghm',
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [],
    pmsiTypes: [PmsiTypeEnum.MCO],
    label: 'GHM initial',
    country: [CountryEnum.FR],
    format: '{presence}StartingGhm',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataTypeEnum.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
    ],
  },
  currentGhm : {
    name: 'currentGhm',
    type: FilterDataTypeEnum.COMPLETION,
    completionName: 'ghm',
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    mode: [ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [],
    pmsiTypes: [PmsiTypeEnum.MCO],
    label: 'GHM courant',
    country: [CountryEnum.FR],
    format: '{presence}CurrentGhm',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataTypeEnum.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
    ],
  },
  startingLevel: {
    name: 'startingLevel',
    type: FilterDataTypeEnum.SELECT,
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [],
    pmsiTypes: [PmsiTypeEnum.MCO],
    label: 'Sévérités initiales',
    country: [CountryEnum.FR],
    options: ['1', '2', '3', '4', 'A', 'B', 'C', 'D', 'E', 'J', 'T', 'Z'],
    default: '1',
    format: '{presence}StartingGhmLevel',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataTypeEnum.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
    ],
  },
  currentLevel: {
    name: 'currentLevel',
    type: FilterDataTypeEnum.SELECT,
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    mode: [ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [],
    pmsiTypes: [PmsiTypeEnum.MCO],
    label: 'Sévérités courantes',
    country: [CountryEnum.FR],
    options: ['1', '2', '3', '4', 'A', 'B', 'C', 'D', 'E', 'J', 'T', 'Z'],
    default: '1',
    format: '{presence}CurrentGhmLevel',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataTypeEnum.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
    ],
  },
  startingGhmType: {
    name: 'startingGhmType',
    type: FilterDataTypeEnum.SELECT,
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [],
    pmsiTypes: [PmsiTypeEnum.MCO],
    label: 'Type de GHM initial',
    country: [CountryEnum.FR],
    options: ['C', 'M', 'K', 'Z'],
    format: '{presence}StartingGhmType',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataTypeEnum.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
    ],
  },
  currentGhmType: {
    name: 'currentGhmType',
    type: FilterDataTypeEnum.SELECT,
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    mode: [ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [],
    pmsiTypes: [PmsiTypeEnum.MCO],
    label: 'Type de GHM courant',
    country: [CountryEnum.FR],
    options: ['C', 'M', 'K', 'Z'],
    format: '{presence}CurrentGhmType',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataTypeEnum.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
    ],
  },
  medicalUnit: {
    name: 'medicalUnit',
    type: FilterDataTypeEnum.MEDICAL_UNIT,
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Unité médicale',
    country: [CountryEnum.FR],
    format: 'medicalUnit',
  },
  dmsGap: {
    name: 'dmsGap',
    type: FilterDataTypeEnum.RANGE,
    valueType: FilterValueTypeEnum.SINGLE_VALUE,
    mode: [ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [],
    pmsiTypes: [PmsiTypeEnum.MCO],
    label: 'Écarts avec la DMS',
    country: [CountryEnum.FR],
    format: 'startingDmsGap',
    range: {
      less: true,
      min: -10,
      interval: 1,
      max: 10,
      more: true,
      unit: 'j',
    },
  },
  stayDuration: {
    name: 'stayDuration',
    type: FilterDataTypeEnum.RANGE,
    valueType: FilterValueTypeEnum.SINGLE_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Durée de séjour',
    country: [CountryEnum.CH, CountryEnum.FR],
    format: 'stayDuration',
    range: {
      less: false,
      min: 0,
      interval: 1,
      max: 20,
      more: true,
      unit: 'j'
    }
  },
  rumCount: {
    name: 'rumCount',
    type: FilterDataTypeEnum.RANGE,
    valueType: FilterValueTypeEnum.SINGLE_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO], // todo https://app.clickup.com/t/86bydp4fz
    label: 'Nombre de RUM',
    country: [CountryEnum.FR],
    format: 'rumCount',
    range: {
      less: false,
      min: 0,
      interval: 1,
      max: 10,
      more: true,
      unit: '',
    }
  },
  patientAge: {
    name: 'patientAge',
    type: FilterDataTypeEnum.RANGE,
    valueType: FilterValueTypeEnum.SINGLE_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Age du patient',
    country: [CountryEnum.CH, CountryEnum.FR],
    format: 'patientAge',
    range: {
      less: false,
      min: 0,
      interval: 1,
      max: 100,
      more: true,
      unit: 'ans',
    },
  },
  // confidence: {
  //   name: 'confidence',
  //   type: 'confidence',
  //   valueType: FilterValueTypeEnum.SINGLE_VALUE,
  //   mode: [], /* DISABLED FOR THE MOMENT */
  //   label: 'Taux de confiance',
  //   country: [CountryEnum.FR],
  //   range: {
  //     less: false,
  //     min: 0,
  //     interval: 1,
  //     max: 100,
  //     more: false,
  //     unit: '%',
  //   },
  // },
  // moneyGap: {
  //   name: 'moneyGap',
  //   type: FilterDataTypeEnum.RANGE,
  //   valueType: FilterValueTypeEnum.SINGLE_VALUE,
  //   mode: [], // TODO clearly set this data point
  //   label: 'Écart de tarifs',
  //   country: [CountryEnum.FR],
  //   format: 'moneyGap',
  //   range: {
  //     less: true,
  //     min: -5000,
  //     interval: 50,
  //     max: 5000,
  //     more: true,
  //     unit: '€',
  //   },
  // },
  gender: {
    name: 'gender',
    type: FilterDataTypeEnum.SELECT,
    valueType: FilterValueTypeEnum.SINGLE_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    format: 'gender',
    label: 'Sexe',
    country: [CountryEnum.CH, CountryEnum.FR],
    options: ['1', '2', '3'],
    labels: {
      1: 'Homme',
      2: 'Femme',
      3: 'Indéterminé',
    },
  },
  hasSession: {
    name: 'hasSession',
    type: FilterDataTypeEnum.SELECT,
    valueType: FilterValueTypeEnum.SINGLE_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO],
    label: 'Seances',
    country: [CountryEnum.FR],
    format: 'hasSession',
    options: ['0', '1'],
    labels: {
      0: 'Non',
      1: 'Oui',
    },
  },
  documentCategory: {
    name: 'documentCategory',
    type: FilterDataTypeEnum.SELECT,
    valueType: FilterValueTypeEnum.MULTI_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Catégorie de document',
    country: [CountryEnum.CH, CountryEnum.FR],
    format: '{presence}DocumentCategory',
    options: Object.keys({ ...documentCategories, ...textualHealthDataCategories }),
    labels: { ...documentCategories, ...textualHealthDataCategories },
    subFilters: [
      {
        name: 'presence',
        type: FilterDataTypeEnum.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        }
      }
    ]
  },
  origin: {
    name: 'origin',
    type: FilterDataTypeEnum.SELECT,
    valueType: FilterValueTypeEnum.SINGLE_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Provenance du patient',
    country: [CountryEnum.FR],
    options: [
      ...createOptionsArray(OriginFr, CountryEnum.FR),
    ],
    optionsLocalised: true,
    default: '',
    format: 'origin',
  },
  destination: {
    name: 'destination',
    type: FilterDataTypeEnum.SELECT,
    valueType: FilterValueTypeEnum.SINGLE_VALUE,
    mode: [ModeEnum.AUTOMATION, ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [AutomationTypeEnum.AI, AutomationTypeEnum.RULES],
    pmsiTypes: [PmsiTypeEnum.MCO, PmsiTypeEnum.SSR],
    label: 'Destination du patient',
    country: [CountryEnum.FR],
    options: [
      ...createOptionsArray(DestinationFr, CountryEnum.FR),
    ],
    optionsLocalised: true,
    default: '',
    format: 'destination',
  },
  initialGme: {
    name: 'initialGme',
    type: FilterDataTypeEnum.COMPLETION,
    completionName: 'gme',
    valueType: FilterValueTypeEnum.SINGLE_VALUE,
    mode: [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [],
    pmsiTypes: [PmsiTypeEnum.SSR],
    label: 'GME initial',
    country: [CountryEnum.FR],
    format: '{presence}InitialGme',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataTypeEnum.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
    ],
  },
  currentGme: {
    name: 'currentGme',
    type: FilterDataTypeEnum.COMPLETION,
    completionName: 'gme',
    valueType: FilterValueTypeEnum.SINGLE_VALUE,
    mode: [ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [],
    pmsiTypes: [PmsiTypeEnum.SSR],
    label: 'GME courant',
    country: [CountryEnum.FR],
    format: '{presence}CurrentGme',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataTypeEnum.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
    ],
  },

  dependency: {
    name: 'dependency',
    type: FilterDataTypeEnum.RANGE,
    valueType: FilterValueTypeEnum.SINGLE_VALUE,
    mode: [ModeEnum.NEW_CODING, ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS],
    automationTypes: [],
    pmsiTypes: [PmsiTypeEnum.SSR],
    isTranslated: true,
    label: 'filter.labels.dependency',
    country: [CountryEnum.FR],
    multiValueRanges: [
      {
        values: [
          'dressing',
          'moving',
          'feeding',
          'continence',
          'behaviour',
          'social'
        ],
        range: {
          less: false,
          min: 1,
          interval: 1,
          max: 4,
          more: false,
          unit: '',
        }
      }, {
        values: ['physical'],
        range: {
          less: false,
          min: 0,
          interval: 1,
          max: 16,
          more: false,
          unit: '',
        }
      }, {
        values: ['cognitive'],
        range: {
          less: false,
          min: 0,
          interval: 1,
          max: 8,
          more: false,
          unit: '',
        }
      },

    ],
    format: 'dep{depType}',
    subFilters: [
      {
        name: 'depType',
        type: FilterDataTypeEnum.SELECT,
        isTranslated: true,
        options: [
          {
            value: 'physical',
            isTitle: true,
          },
          {
            value: 'dressing',
            isTitle: false,
          },
          {
            value: 'moving',
            isTitle: false,
          },
          {
            value: 'feeding',
            isTitle: false,
          },
          {
            value: 'continence',
            isTitle: false,
          },
          {
            value: 'cognitive',
            isTitle: true,
          },
          {
            value: 'behaviour',
            isTitle: false,
          },
          {
            value: 'social',
            isTitle: false,
          },
        ],
        default: '',
        labels: {
          physical: 'dependency.depPhysical',
          dressing: 'dependency.depDressing',
          moving: 'dependency.depMoving',
          feeding: 'dependency.depFeeding',
          continence: 'dependency.depContinence',
          cognitive: 'dependency.depCognitive',
          behaviour: 'dependency.depBehaviour',
          social: 'dependency.depSocial',
        },
      },
    ],
  },
} as { [key: string]: FilterData }
