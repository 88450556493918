interface HomeListItem {
  id: number
}

interface HomeStatsItem {
  id: number
  countStay: number
  countTodoStay: number
}

export function updateHomeList(storedList: HomeStatsItem[], responseList: HomeListItem[], responseStats: HomeStatsItem[]): HomeListItem[] {
  const updatedItems = storedList.map((storedItem) => {
    const responseItem = responseList.find((item) => item.id === storedItem.id)
    // note: no stats for a fetched item means that count are at 0
    const stats = responseStats.find((stat) => stat.id === storedItem.id)
            ?? (responseItem ? { countStay: 0, countTodoStay: 0 } : { countStay: storedItem.countStay, countTodoStay: storedItem.countTodoStay })
    return responseItem ? { ...responseItem, ...stats } : storedItem
  })

  const newItems = responseList.filter((responseItem) => {
    return !updatedItems.some((item) => item.id === responseItem.id)
  }).map((newItem) => {
    const stats = responseStats.find((stat) => stat.id === newItem.id)
            ?? { countStay: 0, countTodoStay: 0 }
    return { ...newItem, ...stats }
  })

  return updatedItems.concat(newItems)
}
