import { AutomationTypeEnum } from '@/store/modules/automation/types'
import { CountryEnum, ModeEnum, PmsiTypeEnum } from '@/store/modules/settings/types'

export enum FilterDataTypeEnum {
  BIOLOGY = 'biology',
  CONFIDENCE = 'confidence',
  COMPLETION = 'completion',
  CONSTANT = 'constant',
  DATE = 'date',
  MEDICAL_UNIT = 'medicalUnit',
  SELECT = 'select',
  RANGE = 'range',
  TEXT = 'text',
  TEXT_CONTENT = 'textContent',
}

export enum FilterValueTypeEnum {
  SINGLE_VALUE = 'singleValue',
  MULTI_VALUE = 'multiValue',
}

export interface FilterDataRange {
  less: boolean
  min: number
  interval: number
  max: number
  more: boolean
  unit: string
}

export interface FilterDataMultiValueRange {
  values: string[]
  range: FilterDataRange
}

export interface LocalizedOption {
  value: string
  label: string
  country: CountryEnum
}

export interface GroupOption {
  value: string
  isTitle: boolean
}

export interface FilterData {
  name: string
  type: FilterDataTypeEnum
  groupage?: boolean
  mode?: ModeEnum[]
  automationTypes?: AutomationTypeEnum[]
  pmsiTypes?: PmsiTypeEnum[]
  valueType?: FilterValueTypeEnum
  label?: string
  country?: CountryEnum[]
  completionName?: string
  format?: string
  isGlobalCondition?: string
  isGlobalOptions?: string[]
  isPlurial?: boolean
  isTranslated?: boolean
  notGlobalOptions?: string[]
  options?: string[] | LocalizedOption[] | GroupOption[]
  optionsLocalised?: boolean
  default?: string
  labels?: { [key: string | number]: string }
  range?: FilterDataRange
  multiValueRanges?: FilterDataMultiValueRange[]
  subFilters?: FilterData[]
}

export type CompletionFilterItem = {
  name: string
  type: string
  value: string
  description: string
}

export interface FilterEvent {
  name: string
  type: string
  value: string
}

export function isLocalizedOption(option: string | LocalizedOption | GroupOption): option is LocalizedOption {
  return option && typeof option === 'object' && 'label' in option && 'country' in option
}

export function isGroupOption(option: string | LocalizedOption | GroupOption): option is GroupOption {
  return option && typeof option === 'object' && 'value' in option && 'isTitle' in option
}

interface Criterion {
  id: string | number
  rawContent: string
  content: string
  type: string
  modifiers: {
    includeAntecedents: boolean
    includeFamilyAntecedents: boolean
    distance: number
  }
}

interface CriteriaGroup {
  label: string
  currentValues: Criterion[]
}

export interface CriteriaList {
  [key: string]: CriteriaGroup
}
