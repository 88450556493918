import { PropType } from 'vue'

import { RawPatient } from '@/store/modules/patient/types'
import { ModeEnum, StayListColumns } from '@/store/modules/settings/types'
import { ExternalPmsiStatusEnum, StayStatus, StayStatusEnum } from '@/store/modules/stay/types'
import { StaySavedSearch } from '@/store/modules/stay-saved-search/types'

export type ListPatient = Pick<RawPatient, 'firstName' | 'lastName' | 'maidenName'>

export interface ListStay {
  id: number
  administrativeLocalStayId: string
  validationDate: string | null
  stayStart: string
  stayEnd: string
  patient: ListPatient
  status: StayStatusEnum
  externalPmsiStatus: ExternalPmsiStatusEnum | null
  statuses: StayStatus[]
  stayDuration: number
  staySavedSearches: StaySavedSearch[]
  moneyGap: number
  comment: string
}

// FIXME: Define a type with the new defineProps syntax, when Vue version >= 3.3, see https://github.com/vuejs/core/issues/4294
export const StayListLineProps = <TStay extends ListStay>() => {
  return {
    stay: { type: Object as PropType<TStay>, required: true },
    columns: { type: Object as PropType<StayListColumns>, required: true },
    allowSelection: { type: Boolean, required: true },
    selected: { type: Boolean, required: true },
    mode: { type: String as PropType<ModeEnum>, required: true }
  }
}
