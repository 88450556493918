<script>
import { mapState } from 'vuex'

import router from '@/router'

export default {
  props: {
    mode: { type: String, required: true }
  },
  computed: {
    ...mapState({
      readingList: (state) => state.stayList.readingList,
    })
  },
  watch: {
    '$route.params.stayId': function(newStayId) {
      this.initializeReadingList(newStayId)
    },
  },
  mounted() {
    this.initializeReadingList(this.$route.params.stayId)
  },
  methods: {
    initializeReadingList(stayId) {
      const isReadingList =  this.$route.query.readingList === '1'
      if (isReadingList) {
        this.$store.dispatch('getCurrentReadingList').then(() => {
          const readingListStayIds = this.$store.state.stayList.readingList.stayIds
          const stayIdx = readingListStayIds.indexOf(parseInt(stayId))
          if (stayIdx !== -1) {
            this.$store.dispatch('navigateReadingList', stayIdx)
          } else {
            router.replace({
              path: `/id/${stayId}`,
              query: { mode: this.mode }
            })
          }
        })
      }
    },
    navigateReadingList(newIndex) {
      const readingListStayIds = this.$store.state.stayList.readingList.stayIds
      const stayId = readingListStayIds[newIndex]

      if (stayId) {
        router.push({
          path: `/id/${stayId}`,
          query: {
            readingList: 1,
            mode: this.mode,
          }
        })
        this.$store.dispatch('navigateReadingList', newIndex)
      }
    },
    endReadingList() {
      this.$store.commit('resetReadingList')
      router.push({ path: '/' })
    }
  }
}
</script>

<template>
  <div
    v-if="readingList.currentIndex !== null && readingList.stayIds.length"
    class="row no-gutters align-items-center"
  >
    <div
      v-if="readingList.currentIndex > 0"
      class="col text-right"
      @click="navigateReadingList(readingList.currentIndex - 1)"
    >
      <div class="btn btn-link">
        &#8592;&nbsp;&nbsp;Précédent
      </div>
    </div>
    <div
      v-else
      class="col"
    />

    <div class="col-auto">
      Séjour {{ readingList.currentIndex + 1 }} / {{ readingList.stayIds.length }}
    </div>

    <div
      v-if="readingList.currentIndex < readingList.stayIds.length -1"
      class="col"
      @click="navigateReadingList(readingList.currentIndex + 1)"
    >
      <div class="btn btn-link">
        Suivant&nbsp;&nbsp;&#8594;
      </div>
    </div>
    <div
      v-else-if="readingList.currentIndex === readingList.stayIds.length - 1"
      class="col"
      @click="endReadingList"
    >
      <div class="btn btn-link">
        Terminer
      </div>
    </div>
    <div
      v-else
      class="col"
    />
  </div>
</template>
